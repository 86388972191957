import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'adminhome',
    component: () => import(/* webpackChunkName: "adminhome" */ '../views/AdminHome.vue'),
    meta: { authOnly: true }
  },
  // {
  //   path: '/activateid',
  //   name: 'activateid',
  //   component: () => import(/* webpackChunkName: "activate_id" */ '../views/active_id.vue'),
  //   meta: { authOnly: true }
  // },
  // {
  //   path: '/reactivateid',
  //   name: 'reactivateid',
  //   component: () => import(/* webpackChunkName: "reactivateid" */ '../views/re_active_id.vue'),
  //   meta: { authOnly: true }
  // },
  {
    path: '/members',
    name: 'members',
    component: () => import(/* webpackChunkName: "members" */ '../views/Members.vue'),
    meta: { authOnly: true }
  },
  // {
  //   path: '/update-topup',
  //   name: 'update-topup',
  //   component: () => import(/* webpackChunkName: "members" */ '../views/UpdateTopup.vue'),
  //   meta: { authOnly: true }
  // },
  // {
  //   path: '/manual-income',
  //   name: 'manual-income',
  //   component: () => import(/* webpackChunkName: "members" */ '../views/ManualIncome.vue'),
  //   meta: { authOnly: true }
  // },
  {
    path: '/actions',
    name: 'actionsView',
    component: () => import(/* webpackChunkName: "members" */  '../views/Action.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/virtualtransfer',
    name: 'fundview',
    component: () => import(/* webpackChunkName: "members" */  '../views/FundView.vue'),
    meta: { authOnly: true }
  },
  // {
  //   path: '/booster-update',
  //   name: 'boosterView',
  //   component: () => import(/* webpackChunkName: "BoosterUpdate" */  '../views/BoosterUpdate.vue'),
  //   meta: { authOnly: true }
  // },
  {
    path: '/buy-token',
    name: 'buy-token',
    component: () => import(/* webpackChunkName: "members" */ '../views/Buy-Token.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/withdrawl',
    name: 'withdrawl',
    component: () => import(/* webpackChunkName: "withdrawl" */ '../views/Withdrawl.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/withdrawl-percentage',
    name: 'withdrawl-percentage',
    component: () => import(/* webpackChunkName: "withdrawl" */ '../views/Withdrawl-percentage.vue'),
    meta: { authOnly: true }
  },
  // {
  //   path: '/closing',
  //   name: 'closing',
  //   component: () => import(/* webpackChunkName: "closing" */ '../views/closing.vue'),
  //   meta: { authOnly: true }
  // },

  
  {
    path: '/roireport',
    name: 'roireport',
    component: () => import(/* webpackChunkName: "levelincome" */ '../views/RoiReport.vue'),
    meta: { authOnly: true }
  }, 
   {
    path: '/roionroireport',
    name: 'roionroireport',
    component: () => import(/* webpackChunkName: "levelincome" */ '../views/RoiOnRoiReport.vue'),
    meta: { authOnly: true }
  }, 
   {
    path: '/directreport',
    name: 'directreport',
    component: () => import(/* webpackChunkName: "levelincome" */ '../views/DirectReport.vue'),
    meta: { authOnly: true }
  }, 
   {
    path: '/rewardreport',
    name: 'rewardreport',
    component: () => import(/* webpackChunkName: "levelincome" */ '../views/RewardReport.vue'),
    meta: { authOnly: true }
  },

  // {
  //   path: '/fast-track',
  //   name: 'fast-track',
  //   component: () => import(/* webpackChunkName: "fast-track" */ '../views/fast_track.vue'),
  //   meta: { authOnly: true }
  // },
  // {
  //   path: '/zero-pin',
  //   name: 'zero-pin',
  //   component: () => import(/* webpackChunkName: "zero-pin" */ '../views/Zero_Pin.vue'),
  //   meta: { authOnly: true }
  // },
  {
    path: '/news',
    name: 'NewsTicker',
    component: () => import(/* webpackChunkName: "zero-pin" */ '../views/NewsTicker.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/user-setting',
    name: 'user-setting',
    component: () => import(/* webpackChunkName: "support" */ '../views/Table-UserView.vue'),
    meta: { authOnly: true }
  },
  // {
  //   path: '/level-open',
  //   name: 'level-open',
  //   component: () => import(/* webpackChunkName: "level-open" */ '../views/Level_Open.vue'),
  //   meta: { authOnly: true }
  // },
  // {
  //   path: '/rate',
  //   name: 'rate',
  //   component: () => import(/* webpackChunkName: "rate" */ '../views/Rate.vue'),
  //   meta: { authOnly: true }
  // },
  {
    path: '/rewards',
    name: 'rewards',
    component: () => import(/* webpackChunkName: "rewards" */ '../views/Rewards.vue'),
    meta: { authOnly: true }
  },
  // {
  //   path: '/levelincome',
  //   name: 'levelincome',
  //   component: () => import(/* webpackChunkName: "levelincome" */ '../views/Level_Income.vue'),
  //   meta: { authOnly: true }
  // },
  // {
  //   path: '/bonusincome',
  //   name: 'bonusincome',
  //   component: () => import(/* webpackChunkName: "bonusincome" */ '../views/Bonus_Income.vue'),
  //   meta: { authOnly: true }
  // },
  {
    path: '/user-setting',
    name: 'user-setting',
    component: () => import(/* webpackChunkName: "support" */ '../views/Table-UserView.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/depositwalletreport',
    name: 'depositwalletreport',
    component: () => import(/* webpackChunkName: "levelincome" */ '../views/DepositWalletReport.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/clientwalletreport',
    name: 'clientwalletreport',
    component: () => import(/* webpackChunkName: "levelincome" */ '../views/ClientWalletReport.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/depositwallet',
    name: 'depositwallet',
    component: () => import(/* webpackChunkName: "levelincome" */ '../views/DepositWallet.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/clientwallet',
    name: 'clientwallet',
    component: () => import(/* webpackChunkName: "levelincome" */ '../views/ClientWallet.vue'),
    meta: { authOnly: true }
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: { guestOnly: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
function isLoggedIn() {
  return localStorage.getItem('admin_token');
}

router.beforeEach((to, from, next) => {



  if (to.matched.some(record => record.meta.authOnly)) {

    if (!isLoggedIn()) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    if (isLoggedIn()) {
      next({
        path: '/',
      })
    } else {

      next()
    }
  } else {
    next();

  }
})
export default router
